import React, { Component } from 'react';
import Helmet from 'react-helmet'
import BlogMenu from './BlogMenu';
import '../../Content/css/blog.css'
import ReactHtmlParser from 'react-html-parser';
import PageHeader from '../PageComponents/PageHeader';
import {animateScroll as scroll } from 'react-scroll'
let gravURL = process.env.REACT_APP_GRAV_URL;
let siteURL = process.env.REACT_APP_APP_URL;
export default class BlogPage extends Component {
	constructor(props) {
	  super(props);
	  this._isMounted = false;
	  this.state = {
	  	gravContent: props.pageContext.frontmatter,
	  	articles: props.pageContext.pages,
	  	filteredArticles: [],
	  	pageMedia: props.pageContext.pageMedia,
	  	media: {},
		uploads: gravURL + '/uploads/images/',
		width: 0,
		height: 0,
		articleSet0: [],
		articleSet1: [],
		articleSet2: [],
		size: 'small',
		activeSet: 0,
		searchField: '',
		wrapperStyle:{},
		headContent: props.pageContext.head,

	  };
	}
	componentDidMount(){
		this._isMounted = true;
		if(this._isMounted === true){
			window.scrollTo(0,0);
			this.updateWindowDimensions();
		}
	}
	componentWillUnmount() {
	  this._isMounted = false;
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}
	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	  	if(window.innerWidth >= 767){
		  	this.setState({
		  		size: 'large',
		  		bannerImage: gravURL + this.state.pageMedia.pageDesktopBanner,
		  	})
		}
		if(window.innerWidth < 767){
		  	this.setState({
		  		size: 'small',
		  		bannerImage: gravURL + this.state.pageMedia.pageMobileBanner,
		  	})
		}
	}
	filterItems(items){
		let filteredItems = this.state.articles;
		filteredItems = filteredItems.filter((item) =>{
			let article = item.searchString.toLowerCase();
			return article.indexOf(items.toLowerCase()) !== -1	
		})
		this.setState({filteredArticles: filteredItems})
	}
	handleSearchFilter({target}){
		this.setState({searchField: target.value,})
		this.filterItems(target.value);
	}
	handleCancelSearch(){
		this.setState({
			searchField: '',
			filteredArticles: [],
		})
	}
	handleActiveSet(){
		this.setState({
			activeSet: this.state.activeSet + 1,
		})
	}
	handleScrollDown(){
		this.setState({scrollHistory: 'scrolled'})
		scroll.scrollTo(this.state.height, {
		 	duration: 300,
  			smooth: true,
  		});
	}
	render() {
		let filterStarted = this.state.searchField.length > 0;
		let dataFound = this.state.filteredArticles.length > 0;
		const {title,keyWords,url,facebookTitle,facebookDesc,shareImg,googleDesc} = this.props.pageContext.head;
		return (
			<main>
				<Helmet>
					<title>Keto Blog | New Image Keto | Alpha Lipid™ SDII Programme</title>	
					{googleDesc&&<meta name="description" content={googleDesc}/>}
					{keyWords&& <meta name="keywords" content={keyWords.toString()} />}
					<meta property="og:image:width" content="1200" />
					<meta property="og:image:height" content="630" />
					<meta property="og:url" content={url?siteURL + url:siteURL} />
					<meta property="og:title" content={facebookTitle?facebookTitle:title}/>
					<meta name="twitter:title" content={facebookTitle?facebookTitle:title}/>
					<meta property="og:description" content={facebookDesc?facebookDesc:googleDesc} />
					<meta name="twitter:description" content={facebookDesc?facebookDesc:googleDesc}/>
					<meta property="og:image" content={gravURL + shareImg} />
					<meta property="og:image:url" content={gravURL + shareImg}/>
					<meta name="twitter:image" content={gravURL + shareImg}/>
					<meta name="twitter:card" content={gravURL + shareImg}/>
				</Helmet>
				<div>
					<div className="headerBannerWrapper clearfix blogPageHeaderBanner bg-gray-900">
						<img src={this.state.bannerImage} alt={this.state.gravContent.title} height="1000px" width="1920px"/>
						<div className="landingSphere">
							<div className="landingSphereTextWrapper">
								<div className="sphereInner">
									<h1>
										{ReactHtmlParser(this.state.gravContent.typeTitle)}
									</h1>
									<a className="bg-primary text-white font-medium rounded-lg py-2 px-4 blogSphereButton" href="#articles">KETO ARTICLES</a>
								</div>
								<span></span>{/* used for alignment*/}
							</div>
						</div>
						<div className="bannerScrollContainer">
				        <button 
				          	className="bannerScrollTrigger" 
				          	onMouseOver={this.handleScrollDown.bind(this)}
				          	onFocus={this.handleScrollDown.bind(this)}>
				          	<div className="scrollDownArrow mx-auto">
					          	<span className="flaticon-down-arrow"></span>
					          	<span className="flaticon-down-arrow"></span>
					          	<span className="flaticon-down-arrow"></span>
					        </div>
				         </button>
			      </div>
					</div>
					
				</div>
				<section>
				<PageHeader  mainHeading={this.state.gravContent.pageMainHeading} subHeading={this.state.gravContent.pageSubHeading}
				/>
				<div className="text-center mt-4">
					<label htmlFor="search-articles">Search Articles</label>
				</div>
				<div className="searchFilter">
					<span className="flaticon-search"></span>
					<input type="text" 
						onChange={this.handleSearchFilter.bind(this)} 
						value={this.state.searchField}
						className="searchField"
						placeholder="Find an article"
						name="search-articles"
						id="search-articles"
					/>
					{filterStarted&&
						<button className="flaticon-close" onClick={this.handleCancelSearch.bind(this)}>
							<span className="hidden">Cancel</span>
						</button>
					}
				</div>
				{filterStarted&&
					<div>
						{dataFound?
							null:
							<div className="searchFilterNoResults"  style={this.state.wrapperStyle}>
								<h3>No articles found.<br/>Please try searching something else</h3>
								
							</div>
						}
					</div>
				}
					{dataFound?
					<div className="blogPage clearfix pageGrid" id="articles">
						<BlogMenu articles={this.state.filteredArticles}
							load={true}
							size={this.state.size}
							activeSet={this.state.activeSet}
							set={0}
							handleActiveSet={this.handleActiveSet.bind(this)}
						/>
					</div>
					:

					<div className="blogPage clearfix pageGrid" id="articles">
						<BlogMenu articles={this.state.articles}
							load={true}
							activeSet={this.state.activeSet}
							set={0}
							handleActiveSet={this.handleActiveSet.bind(this)}
							size={this.state.size}
						/>
					</div>
					
				}
				</section>
			</main>
		);
	}
}

import React, {Component} from 'react';
import '../../Content/css/recipesPage.css';
import ReactHtmlParser from 'react-html-parser';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {Link} from 'gatsby'
let gravURL = process.env.REACT_APP_GRAV_URL;
export default class RecipeBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recipes: this.props.articles,
      gravURL: gravURL + '/uploads/images/articles/',
      load: this.props.load
    };
  }
  handleLoadArticles() {
    this.setState({load: true})
    this
      .props
      .handleActiveSet();
  }
  render() {
    let large = this.props.size === 'large';
    let list = this.props.articles.length > 6;
    let {load} = this.state;
    let setActive = (this.props.set - 1) === this.props.activeSet;
    let items = this
      .props
      .articles
      .map((item, key) => <li className="text-center" key={key}>
        <Link to={item.link.link}>
          <div className="lg:flex lg:flex-wrap lg:items-center">
            <div className="lg:w-6/12 px-1">
              <div className="bg-gray-200">
                {large
                  ? <LazyLoadImage
                      threshold={200}
                      src={gravURL + item.media.thumbnail}
                      alt={item.frontmatter.title}
                      className="blogMenuImg"
                      width="500"
                      height="263"/>
                  : <LazyLoadImage
                    threshold={200}
                    src={gravURL + item.media.thumbnail}
                    alt={item.frontmatter.title}
                    className="blogMenuImg"
                    width="500"
                    height="263"/>
}
              </div>
            </div>
            <div className="lg:w-6/12 px-1">
              <h4>{item.frontmatter.title}</h4>
              {large
                ? <p>{ReactHtmlParser(item.summary)}
                    <small>Read more</small>
                  </p>
                : <p>{ReactHtmlParser(item.longerSummary)}
                  <small>Read more</small>
                </p>
}
            </div>
          </div>
        </Link>
      </li>);
    return (
      <div className="blogMenu">
        {load
          ? <div>
              {list && <ul className="blogMenuRow ">
                {items}
              </ul>
}
            </div>
          : <div className="articleLoadButtonWrapper text-center">
            {setActive && <button
              onClick={this
              .handleLoadArticles
              .bind(this)}
              className="button darkButton">
              Load More Articles
            </button>
}
          </div>
}
      </div>
    );

  }

}
